<template>
  <div>
    <dx-util-popup
      ref="reconcileWithFileRef"
      :show-close-button="true"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-title="true"
      :full-screen="false"
      width="95vw"
      height="95vh"
      :title="reconcileWithFilePopupTitle"
      @shown="onShownReconcileWithFilePopup"
      @hidden="onHiddenReconcileWithFilePopup"
    >
    <template>
      <div>
        <div class="d-flex justify-content-between rounded bg-light-primary p-half my-0 w-100 align-items-center">
          <div class="d-flex flex-column text-center">
            <strong>Reconcile Items </strong>
            <h4>{{ shipmentID }}</h4>
          </div>
          <div class="d-flex flex-column text-center">
            <strong>Available QTY</strong>
            <h4>{{ availableCount }}</h4>
          </div>
          <div class="d-flex flex-column text-center">
            <strong>Sent QTY</strong>
            <h4>{{ sentCount }}</h4>
          </div>
          <div>
            <dx-util-button v-if="showCreateMissingButton" type="success"
              text="Create And Receive Missing Items" class="mr-1"
              hint="Please note that this action will create missing items into the customer's Needs Action batch and receive them automatically and finalize the reconcilation process."
              @click.native="createMissingItems(true)"
            />
            <dx-util-button text="Cancel" :on-click="onClose" type="danger" class="mr-1" />
            <dx-util-button v-if="!showCreateMissingButton" text="Save" type="success" class="mr-1" :on-click="onPreSubmit" />
          </div>
        </div>
        <div class="p-1">
          <div class="row">
            <div v-if="!isUploaded" class="col-lg-12">
              <div class="d-flex">
                <div class="mr-4">
                  <h4 class="">
                    Upload Data
                  </h4>
                  <div class="card-text mb-half">
                    <span>Upload your data for reconcilation</span>
                  </div>
                </div>
                <div class="ml-4">
                  <input ref="fileInput" type="file" accept=".tsv" hidden @change="fileLoaded">
                  <dx-util-button class="py-half px-2 " text="Browse the file" type="default" @click.native="$refs.fileInput.click()" />
                </div>
              </div>
            </div>
            <div v-else class="col-lg-12">
              <div class="row">
                <div class="col-lg-3">
                    <span class="d-block badge badge-pill badge-success">
                      Match
                    </span>
                </div>
                <div class="col-lg-3">
                  <span class="d-block badge badge-warning badge-pill">
                    More Items Available
                  </span>
                </div>
                <div class="col-lg-2">
                  <span class="d-block badge badge-danger badge-pill">
                    Sent More Than Available
                  </span>
                </div>
                <div class="col-lg-2">
                  <span class="d-block badge badge-secondary badge-pill">
                    0 Sent or Available
                  </span>
                </div>
                <div class="col-lg-2">
                  <span class="d-block badge badge-primary badge-pill">
                    In-Transit
                  </span>
                </div>
              </div>
              <dx-data-grid
                id="inventoryGrid"
                ref="inventoryGridRef"
                height="calc(100vh - 240px)"
                class="mt-1"
                :data-source="dataSource"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                column-resizing-mode="widget"
                :column-auto-width="true"
                :show-column-lines="true"
                :show-row-lines="true"
                :show-borders="true"
                :row-alternation-enabled="false"
                :hover-state-enabled="true"
                :customize-columns="customizeColumns"
                @initialized="onInitialized"
                @content-ready="onContentReady"
              >
                <dx-sorting mode="single" />
                <dx-paging :page-size="pagerOptions.pageSize" />
                <dx-pager
                  :allowed-page-sizes="pagerOptions.allowedPageSizes"
                  :show-info="pagerOptions.showInfo"
                  :show-navigation-buttons="pagerOptions.showNavigationButtons"
                  :show-page-size-selector="pagerOptions.showPageSizeSelector"
                  :visible="pagerOptions.visible"
                />
                <dx-column data-field="title" caption="Title" :css-class="'max-width-column'" />
                <dx-column data-field="inTransit" caption="InTransit QTY" alignment="left" :width="140" cell-template="inTransitTemplate" />
                <dx-column data-field="quantity" caption="Available QTY" alignment="left" :width="140" cell-template="quantityTemplate" />
                <dx-column data-field="sentQuantity" caption="Sent QTY" alignment="left" :width="140" cell-template="quantityTemplate" />
                <dx-column data-field="upc" caption="UPC" :width="120" />
                <dx-column data-field="asin" caption="ASIN" :width="120" />
                <dx-column data-field="fnsku" caption="FNSKU" :width="110" />
                <dx-column data-field="storeName" caption="Store" />
                <dx-column data-field="warehouseName" caption="Warehouse" />
                <template #inTransitTemplate="{data}">
                  <span v-if="data.item.inTransit > 0" class="d-block badge badge-pill badge-primary">
                    {{ data.value }}
                  </span>
                </template>
                <template #quantityTemplate="{data}">
                  <span :class="`d-block badge badge-pill badge-${resolveColorVariant(data.data)}`">
                    {{ data.value }}
                  </span>
                </template>
              </dx-data-grid>
            </div>
          </div>
        </div>
        <dx-util-popup
          :visible="popupVisible"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-close-button="false"
          :show-title="true"
          :width="500"
          :height="250"
          title="Confirmation"
        >
          <dx-util-position at="center" my="center" />
          <dx-util-toolbar-item toolbar="bottom" location="after" template="closePopupButton" />
          <template #closePopupButton>
            <dx-util-button
              id="closePopupButton"
              text="Cancel"
              type="danger"
              styling-mode="contained"
              @click="setDefaultPopup()"
            />
          </template>
          <dx-util-toolbar-item toolbar="bottom" location="after" template="acceptPopupButton" />
          <template #acceptPopupButton>
            <dx-util-button
              id="acceptPopupButton"
              text="Reconcile"
              hint="Reconciles batch and deducts inventory accordingly"
              type="default"
              styling-mode="contained"
              @click="onSubmit()"
            />
          </template>
          <p>{{ confirmationMessage }}</p>
      </dx-util-popup>
    </div>
    </template>
    </dx-util-popup>
  </div>
</template>

<script>
import inventoryService from '@/http/requests/inventory/inventoryService'
import outboundShippingService from '@/http/requests/outbound/outboundShippingService'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import shipService from '@/http/requests/ship/shipService'
import { Notify } from '@robustshell/utils/index'
import parseInt from 'lodash/parseInt'
import includes from 'lodash/includes'

export default {
  mixins: [GridBase],
  props: {
    companyInfo: {
      type: Object,
      required: true,
    },
    componentId: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    reconcileWithFilePopupTitle: '',
    isUploaded: false,
    file: null,
    shipmentID: '',
    companyId: 0,
    parsedDataFromFile: [],
    missingItems: [],
    shortOfItems: [],
    dataSource: [],
    sentCount: 0,
    availableCount: 0,
    receiveItems: false,
    isShortOfItems: false,
    isThereIssue: false,
    isIssueFixable: false,
    popupVisible: false,
    confirmationMessage: '',
  }),
  computed: {
    showMissingButton() {
      return this.missingItems.length > 0
    },
    showCreateMissingButton() {
      return this.isShortOfItems || this.missingItems.length > 0
    },
    reconcileWithFilePopupIns() {
      return this.$refs.reconcileWithFileRef.instance
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler(current) {
        if (current) {
          // this.dataSourceArr = []
          this.createReconcileWithFilePopup()
        }
      },
    },
  },
  methods: {
    createReconcileWithFilePopup() {
      this.reconcileWithFilePopupIns.show()
    },
    onShownReconcileWithFilePopup() {
      this.reconcileWithFilePopupTitle = 'Reconciliation'
    },
    onHiddenReconcileWithFilePopup() {
      this.onClose()
    },
    showConfirm() {
      this.popupVisible = true
    },
    setDefaultPopup() {
      this.confirmationMessage = ''
      this.popupVisible = false
    },
    resolveColorVariant(item) {
      if (parseInt(item.quantity) === 0 || parseInt(item.sentQuantity) === 0) return 'secondary'
      if (parseInt(item.quantity) === parseInt(item.sentQuantity)) return 'success'
      if (parseInt(item.quantity) > parseInt(item.sentQuantity)) return 'warning'
      if (parseInt(item.quantity) < parseInt(item.sentQuantity)) return 'danger'
      return 'primary'
    },
    getQueryParams() {
      return '&accountNo='.concat(this.companyInfo.accountNo)
        .concat('&warehouseId=').concat(this.companyInfo.warehouseId)
        .concat('&storeId=')
        .concat(this.companyInfo.storeId)
        .concat('&itemAction=')
        .concat(this.companyInfo.itemAction)
        .concat('&amzBatchId=')
        .concat(this.shipmentID)
    },
    getInventoryData() {
      const params = this.getQueryParams()
      inventoryService.fetchItemsByAsinAndParams(params, this.parsedDataFromFile).then(result => {
        this.dataSource.splice(0, this.dataSource.length)
        const { data } = result
        const itemSetFromFile = JSON.parse(JSON.stringify(this.parsedDataFromFile))
        let c = 0
        data.forEach(item => {
          const index = this.parsedDataFromFile.findIndex(u => u.asin === item.asin)
          if (index >= 0) {
            if (this.companyId === 0) {
              this.companyId = data[c].companyId
            }
            data[c].sentQuantity = this.parsedDataFromFile[index].count
            this.availableCount += parseInt(item.quantity)
            if (parseInt(item.quantity) < parseInt(data[c].sentQuantity)) {
              this.isThereIssue = true
              if (parseInt(item.inTransit) > 0) {
                this.isIssueFixable = true
              }
            }
          } else {
            this.isThereIssue = true
          }
          const mIndex = itemSetFromFile.findIndex(u => u.asin === item.asin && u.count > item.quantity)
          if (mIndex >= 0) {
            const sentItem = itemSetFromFile[mIndex]
            this.isShortOfItems = true
            const fIndex = this.shortOfItems.findIndex(u => u.asin === sentItem.asin)
            if (fIndex === -1) {
              this.shortOfItems.push({
                ProductTitle: sentItem.title,
                ASIN: sentItem.asin,
                UPC: sentItem.upc,
                FNSKU: sentItem.fnsku,
                MSKU: sentItem.msku,
                NumberOfUnits: parseInt(sentItem.count - item.quantity),
                reservedCount: parseInt(sentItem.count),
                warehouseId: this.companyInfo.warehouseId,
                companyId: this.companyInfo.accountNo,
                storeId: this.companyInfo.storeId,
              })
            } else {
              this.shortOfItems[fIndex].NumberOfUnits += parseInt(sentItem.count - item.quantity)
              this.shortOfItems[fIndex].reservedCount += parseInt(sentItem.count)
            }
          }
          const iIndex = itemSetFromFile.findIndex(u => u.asin === item.asin)
          if (iIndex >= 0) {
            itemSetFromFile.splice(iIndex, 1)
          }
          c += 1
        })
        this.dataSource.push(...data)
        this.missingItems = []
        itemSetFromFile.forEach(item => {
          this.missingItems.push({
            ProductTitle: item.title,
            ASIN: item.asin,
            UPC: item.upc,
            FNSKU: item.fnsku,
            MSKU: item.msku,
            NumberOfUnits: parseInt(item.count),
            reservedCount: parseInt(item.count),
            warehouseId: this.companyInfo.warehouseId,
            companyId: this.companyInfo.accountNo,
            storeId: this.companyInfo.storeId,
          })
          this.dataSource.push(item)
          this.dataSource[this.dataSource.length - 1].quantity = 0
          this.dataSource[this.dataSource.length - 1].sentQuantity = item.count
        })
      })
    },
    fileLoaded(event) {
      this.file = event.target.files ? event.target.files[0] : null
      if (this.file != null) {
        this.isUploaded = !this.isUploaded
      }
      const reader = new FileReader()
      reader.readAsText(this.file, 'UTF-8')
      reader.onload = evt => {
        const lines = evt.target.result.split('\n')
        let line = 0
        const indexes = []
        const keys = []
        let asinIndex = -1
        for (; line < lines.length; line++) {
          const tokens = lines[line].split('\t')
          // Get the shipment ID from file. This is an id assigned by Amazon to each shipment batch
          // We use this id as our shipment batch name, Example : Shipment ID \t FBA123456789
          if (includes(tokens[0], 'Shipment ID') && tokens.length >= 2) {
            this.shipmentID = tokens[1].replace(/[\n\r]+/g, '')
          }
          // Title of the data. The actual data rows start from next line
          if (includes(tokens[0], 'Merchant') || includes(tokens[0], 'MSKU')) {
            line += 1
            indexes.push(0)
            keys.push('msku')
            for (let i = 1; i < tokens.length; i++) {
              // We want to find the desired colomns.
              // Colomn: [0]MSKU; [1]Title; [2]ASIN; [3]FNSKU [4]external-id(UPC|EAN); [9]Count
              if (includes(tokens[i], 'Title')) {
                indexes.push(i)
                keys.push('title')
              } else if (includes(tokens[i], 'ASIN')) {
                indexes.push(i)
                keys.push('asin')
                if (asinIndex === -1) {
                  asinIndex = i
                }
              } else if (includes(tokens[i], 'FNSKU')) {
                indexes.push(i)
                keys.push('fnsku')
              } else if (includes(tokens[i], 'external')) {
                indexes.push(i)
                keys.push('upc')
              } else if (includes(tokens[i], 'Shipped')) {
                indexes.push(i)
                keys.push('count')
              }
            }
            break
          }
        }
        const inputMap = new Map()
        // We start to read the actual data
        for (; line < lines.length; line++) {
          if (lines[line] !== null && lines[line] !== '') {
            const tokens = lines[line].split('\t')
            if (tokens && tokens.length !== 0) {
              const asin = tokens[asinIndex].trim().replace(/[\n\r]+/g, '')
              const row = inputMap.has(asin) ? inputMap.get(asin) : { count: 0 }
              for (let i = 0; i < indexes.length; i++) {
                if (keys[i] === 'upc') {
                  const code = tokens[indexes[i]].split(':')
                  if (code !== null && code.length > 1) {
                    row[keys[i]] = code[1].trim().replace(/[\n\r]+/g, '')
                  }
                } else if (keys[i] === 'count') {
                  const count = parseInt(tokens[indexes[i]].trim().replace(/[\n\r]+/g, ''))
                  row[keys[i]] += count
                  this.sentCount += count
                } else {
                  row[keys[i]] = tokens[indexes[i]].trim().replace(/[\n\r]+/g, '')
                }
              }
              inputMap.set(asin, row)
            }
          }
        }
        this.parsedDataFromFile.push(...inputMap.values())
        this.getInventoryData()
      }
    },
    onPreSubmit() {
      if (this.isThereIssue && this.isIssueFixable) {
        Notify.warning('There are items inTransit. Please receive them first to fix not matching items')
        return
      }
      if (this.isThereIssue) {
        Notify.error('This batch cannot be reconciled due to not matching item quantity in inventory')
        return
      }
      this.receiveItems = false
      this.confirmationMessage = 'Are you sure you want to reconcile this batch? Please note that this action will create missing items into the customer\'s Needs Action batch if there is any.'
      this.showConfirm()
    },
    onSubmit() {
      const shipmentBatchWithItems = { amazonBatch: true }
      const shipmentBatch = {
        name: this.shipmentID,
        storeId: this.companyInfo.storeId,
        warehouseId: this.companyInfo.warehouseId,
      }
      shipmentBatchWithItems.accountNo = this.companyInfo.accountNo
      shipmentBatchWithItems.batch = shipmentBatch
      const items = []
      this.parsedDataFromFile.forEach(item => {
        const index = this.dataSource.findIndex(u => u.asin === item.asin)
        if (this.dataSource[index].quantity >= item.count) {
          items.push({
            storeId: this.companyInfo.storeId,
            productId: this.dataSource[index].productId,
            quantity: item.count,
            msku: item.msku,
            fnsku: item.fnsku,
          })
        }
      })
      shipmentBatchWithItems.items = items
      shipmentBatchWithItems.receiveItems = this.receiveItems
      shipmentBatchWithItems.missingItems = this.missingItems
      shipmentBatchWithItems.missingItems.push(...this.shortOfItems)
      outboundShippingService.createBatchAmazon(shipmentBatchWithItems)
        .then(() => {
          this.onClose()
        })
      this.setDefaultPopup()
    },
    createMissingItems(e) {
      this.receiveItems = e
      this.$swal({
        title: 'Are you sure you want to reconcile this?',
        text: 'Please note that this action will create missing items into the customer\'s Needs Action batch and receive them automatically and finalize the reconcilation process!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Create',
        customClass: {
          container: 'swal-container',
          popup: 'swal-popup',
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value && e) {
            this.onSubmit()
          } else if (result.value) {
            this.missingItems.push(...this.shortOfItems)
            shipService.createMissingItems(this.missingItems)
              .then(() => {
                this.onClose()
              })
              .finally(() => {
                Notify.success('Items have been created and added to customer shared batch. Please receive them in order to reconcile the batch.')
              })
          }
        })
    },
    reset() {
      this.isUploaded = false
      this.receiveItems = false
      this.isShortOfItems = false
      this.file = null
      this.shipmentID = ''
      this.sentCount = 0
      this.availableCount = 0
      this.missingItems = []
      this.shortOfItems = []
      this.parsedDataFromFile.splice(0, this.parsedDataFromFile.length)
      this.dataSource.splice(0, this.dataSource.length)
    },
    onClose() {
      this.reconcileWithFilePopupIns.hide()
      this.reset()
      this.$emit('on-reload-page')
    },
  },
}
</script>

<style>
.swal-container {
  z-index: 10000;
}
.swal-popup {
  width: 420px;
  height: 420px;
}
.max-width-column {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
